import React from 'react';
import { useNavigate } from 'react-router-dom';
import Snowfall from './Snowfall';

function Home() {
  const navigate = useNavigate();

  return (
    <div className="home-container">
      <Snowfall />
      <h1>Welcome to SmartSanta</h1>
      <div className="welcome-content">
        <h2>Your AI-Powered Gift Assistant</h2>
        <p>Finding the perfect gift has never been easier! Let our AI help you discover thoughtful presents tailored to your loved ones.</p>
        <button 
          className="start-button"
          onClick={() => navigate('/recommendations')}
        >
          Start Finding Gifts
        </button>
      </div>
    </div>
  );
}

export default Home; 