import React, { useState, useEffect } from 'react';
import { getRecommendations } from '../services/geminiService';
import Snowfall from './Snowfall';

function AIRecommendations() {
  const [preferences, setPreferences] = useState({
    recipient: '',
    recipientType: '',
    age: '',
    interests: [],
    budget: ''
  });
  const [suggestedInterests, setSuggestedInterests] = useState([]);
  const [recommendations, setRecommendations] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customRecipient, setCustomRecipient] = useState('');
  const [customInterest, setCustomInterest] = useState('');
  const [currentInterest, setCurrentInterest] = useState('');

  const recipientTypes = {
    'Select Recipient': [],
    'Mom': ['Cooking', 'Gardening', 'Self-care', 'Home Decor', 'Reading'],
    'Dad': ['Tools', 'Sports', 'Technology', 'Grilling', 'Outdoor Activities'],
    'Partner': ['Fashion', 'Electronics', 'Experience Gifts', 'Jewelry', 'Travel'],
    'Child': ['Toys', 'Educational Games', 'Arts & Crafts', 'Sports Equipment', 'Books'],
    'Teen': ['Gaming', 'Electronics', 'Fashion', 'Music', 'Sports'],
    'Friend': ['Hobbies', 'Entertainment', 'Fashion', 'Tech Gadgets', 'Experience Gifts'],
    'Grandparent': ['Comfort Items', 'Photo Gifts', 'Books', 'Garden Tools', 'Health & Wellness'],
    'Custom': []
  };

  const ageRanges = [
    'Select Age Range',
    '0-2 years',
    '3-5 years',
    '6-12 years',
    '13-17 years',
    '18-24 years',
    '25-34 years',
    '35-44 years',
    '45-54 years',
    
  ];

  useEffect(() => {
    if (preferences.recipientType) {
      setSuggestedInterests(recipientTypes[preferences.recipientType]);
    }
  }, [preferences.recipientType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      const submissionPreferences = {
        ...preferences,
        interests: preferences.interests.join(', ')
      };
      const response = await getRecommendations(submissionPreferences);
      setRecommendations(response);
    } catch (err) {
      setError(err.message || 'Failed to get recommendations. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const submissionPreferences = {
        ...preferences,
        interests: preferences.interests.join(', '),
        recipientType: preferences.recipientType === 'Custom' ? customRecipient : preferences.recipientType
      };
      const response = await getRecommendations(submissionPreferences);
      setRecommendations(response);
    } catch (err) {
      setError(err.message || 'Failed to refresh recommendations. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ai-recommendations">
      <Snowfall />
      <h2 style={{
        textAlign: 'center',
        color: 'white',
        textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
        marginBottom: '2rem'
      }}>AI-Powered Gift Recommendations</h2>
      
      <form onSubmit={handleSubmit} className="gift-finder">
        <select
          value={preferences.recipientType}
          onChange={(e) => setPreferences({
            ...preferences,
            recipientType: e.target.value,
            interests: []
          })}
          className="form-select"
        >
          {Object.keys(recipientTypes).map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        {preferences.recipientType === 'Custom' && (
          <input
            type="text"
            placeholder="Enter who you're buying for"
            value={customRecipient}
            onChange={(e) => setCustomRecipient(e.target.value)}
            className="form-select"
          />
        )}

        <select
          value={preferences.age}
          onChange={(e) => setPreferences({...preferences, age: e.target.value})}
          className="form-select"
        >
          {ageRanges.map(range => (
            <option key={range} value={range}>
              {range}
            </option>
          ))}
        </select>

        <div className="tag-input-container">
          <div className="tags">
            {preferences.interests.map((interest, index) => (
              <span key={index} className="tag">
                {interest}
                <button 
                  onClick={() => {
                    const newInterests = preferences.interests.filter((_, i) => i !== index);
                    setPreferences({...preferences, interests: newInterests});
                  }}
                  className="tag-remove"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
          <input
            type="text"
            placeholder="Add interests (press Enter)"
            value={currentInterest}
            onChange={(e) => setCurrentInterest(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && currentInterest.trim()) {
                e.preventDefault();
                setPreferences({
                  ...preferences,
                  interests: [...preferences.interests, currentInterest.trim()]
                });
                setCurrentInterest('');
              }
            }}
            className="tag-input"
          />
        </div>

        <input
          type="number"
          placeholder="Budget"
          value={preferences.budget}
            onChange={(e) => setPreferences({...preferences, budget: e.target.value})}
        />

        <button type="submit" disabled={loading}>
          {loading ? 'Getting Recommendations...' : 'Get Recommendations'}
        </button>
      </form>

      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <div className="loading-text">Finding Perfect Gifts</div>
          <div className="loading-subtext">Our AI is searching for personalized recommendations...</div>
        </div>
      )}
      
      {error && <div className="error">{error}</div>}
      
      {recommendations && !loading && (
        <div className="recommendations-result">
          <div className="recommendations-header">
            <h3>Your Personalized Recommendations:</h3>
            <button 
              onClick={handleRefresh} 
              disabled={loading}
              className="refresh-button"
            >
              {loading ? 'Refreshing...' : '🔄 New Ideas'}
            </button>
          </div>
          <div className="recommendations-text">
            {recommendations.split('\n').map((line, index) => {
              if (line.includes('[View on Amazon]')) {
                const linkMatch = line.match(/\[View on Amazon\]\((.*?)\)/);
                if (linkMatch && linkMatch[1]) {
                  return (
                    <a 
                      key={index}
                      href={linkMatch[1]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="amazon-button"
                    >
                      View on Amazon
                    </a>
                  );
                }
              }
              return <p key={index}>{line}</p>;
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default AIRecommendations; 