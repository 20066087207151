import { GoogleGenerativeAI } from "@google/generative-ai";

const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

export const getRecommendations = async (preferences) => {
  try {
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });
    
    const prompt = `Act as a gift recommendation expert. I need 5 specific gift suggestions based on these details:
    - Recipient: ${preferences.recipient}
    - Age: ${preferences.age}
    - Interests: ${preferences.interests}
    - Budget: ${preferences.budget}
    
    For each gift, provide the following in this exact format:
    1. Gift Name: [name]
    Price Range: [price]
    Why it's a good match: [reason]
    Search term: [2-4 word specific search term]
    [View on Amazon](https://www.amazon.com/s?k=[search term with plus signs]&tag=${process.env.REACT_APP_AMAZON_AFFILIATE_ID})

    2. Gift Name: [name]
    Price Range: [price]
    Why it's a good match: [reason]
    Search term: [2-4 word specific search term]
    [View on Amazon](https://www.amazon.com/s?k=[search term with plus signs]&tag=${process.env.REACT_APP_AMAZON_AFFILIATE_ID})

    3. Gift Name: [name]
    Price Range: [price]
    Why it's a good match: [reason]
    Search term: [2-4 word specific search term]
    [View on Amazon](https://www.amazon.com/s?k=[search term with plus signs]&tag=${process.env.REACT_APP_AMAZON_AFFILIATE_ID})

    4. Gift Name: [name]
    Price Range: [price]
    Why it's a good match: [reason]
    Search term: [2-4 word specific search term]
    [View on Amazon](https://www.amazon.com/s?k=[search term with plus signs]&tag=${process.env.REACT_APP_AMAZON_AFFILIATE_ID})

    5. Gift Name: [name]
    Price Range: [price]
    Why it's a good match: [reason]
    Search term: [2-4 word specific search term]
    [View on Amazon](https://www.amazon.com/s?k=[search term with plus signs]&tag=${process.env.REACT_APP_AMAZON_AFFILIATE_ID})

    Note: Replace spaces with plus signs in the search terms for Amazon Links.`;

    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    
    if (!text) {
      throw new Error('Empty response from AI');
    }

    return text;
  } catch (error) {
    console.error('Error in getRecommendations:', error);
    throw error;
  }
}; 