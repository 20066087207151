import React, { useEffect, useState } from 'react';

function Snowfall() {
  const [snowflakes, setSnowflakes] = useState([]);
  
  useEffect(() => {
    const snowflakeCharacters = ['❅', '❆', '❄', '✧', '✦'];
    
    const createSnowflake = () => {
      return {
        id: Math.random(),
        left: `${Math.random() * 100}vw`,
        character: snowflakeCharacters[Math.floor(Math.random() * snowflakeCharacters.length)],
        animationDuration: `${Math.random() * 5 + 5}s`,
        size: `${Math.random() * 1 + 0.5}em`,
        opacity: Math.random() * 0.5 + 0.5
      };
    };

    // Initial snowflakes
    const initialFlakes = Array.from({ length: 50 }, createSnowflake);
    setSnowflakes(initialFlakes);

    // Add new snowflakes periodically
    const addNewSnowflake = () => {
      setSnowflakes(prevFlakes => {
        const newFlakes = [...prevFlakes, createSnowflake()];
        // Remove snowflakes that have fallen off screen (older than 10 seconds)
        if (newFlakes.length > 100) {
          return newFlakes.slice(-100);
        }
        return newFlakes;
      });
    };

    // Add new snowflakes every 200ms
    const interval = setInterval(addNewSnowflake, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {snowflakes.map((flake) => (
        <div
          key={flake.id}
          className="snowflake"
          style={{
            left: flake.left,
            fontSize: flake.size,
            opacity: flake.opacity,
            animationDuration: flake.animationDuration
          }}
        >
          {flake.character}
        </div>
      ))}
    </>
  );
}

export default Snowfall; 